import React, { useState } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js"
import illustration from "images/email-illustration.svg"
import { TextField, Grid } from '@material-ui/core'
import ToastAutoHide from 'components/ToastAutoHide'

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
	tw`md:w-7/12 mt-16 md:mt-0`,
	props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
	`background-image: url("${props.imageSrc}");`,
	tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`



const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default function SugerenciasForm() {
	const [cuerpo, setCuerpo] = useState({
		nombre: "",
		apellido: "",
		dni: '',
		email: '',
		telefono: '',
		mensaje: ''
	})
	const [mensaje, setMensaje] = useState({ ident: null, message: null, type: null })

	const onChange = e => {
		setCuerpo({
			...cuerpo,
			[e.target.name]: e.target.value
		})
	}

	const emailSend = () => {
		window.emailjs.send(
			"probotector",
			"sugerencias",
			{
				"to": "programador@gnt.pe, lipaduanas@lipaduanas.com",
				"to_name": "LIP Aduanas S.A.",
				"nombre": cuerpo.nombre,
				"apellido": cuerpo.apellido,
				"dni": cuerpo.dni,
				"email": cuerpo.email,
				"telefono": cuerpo.telefono,
				"mensaje": cuerpo.mensaje
			}).then(() => {
				setMensaje({
					ident: new Date().getTime(),
					message: 'Sugerencia enviada correctamente',
					type: 'success'
				})
				setCuerpo({
					nombre: "",
					apellido: "",
					dni: '',
					email: '',
					telefono: '',
					mensaje: ''
				})
			})
	}

	return (
		<Container>
			<ToastAutoHide message={mensaje} />
			<TwoColumn>
				<ImageColumn>
					<Image imageSrc={illustration} />
				</ImageColumn>
				<TextColumn textOnLeft={true}>
					<TextContent>
						<Subheading></Subheading>
						<Heading><>Sugerencias, <span tw="text-blue-500">quejas</span><wbr /> y/o reclamos.</></Heading>
						<Description></Description>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField name="nombre" value={cuerpo.nombre || ''} onChange={onChange} label="Nombre" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField name="apellido" value={cuerpo.apellido || ''} onChange={onChange} label="Apellido" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField name="dni" value={cuerpo.dni || ''} onChange={onChange} label="DNI*" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField name="email" value={cuerpo.email || ''} onChange={onChange} label="Email" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField name="telefono" value={cuerpo.telefono || ''} onChange={onChange} label="Teléfono" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField multiline rows={4} name='mensaje' value={cuerpo.mensaje || ''} onChange={onChange} label="Mensaje" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<SubmitButton onClick={() => emailSend()}>Enviar</SubmitButton>
							</Grid>
						</Grid>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
};
