import React from 'react'
import { useParams } from 'react-router-dom'
import AnimationRevealPage from "helpers/AnimationRevealPage.js"


import Inicio from "demos/Inicio.js"
import ServiciosLandingPage from "demos/ServiciosLandingPage.js"


import AgencyLandingPageImageSrc from "images/demo/AgencyLandingPage.jpeg"
import RestaurantLandingPageImageSrc from "images/demo/RestaurantLandingPage.jpeg"


import CotizacionPage from "pages/Cotizacion.js"
import SugerenciasPage from "pages/Sugerencias"
import AboutUsPage from "pages/AboutUs.js"
import RegistroDatosPage from "pages/RegistroDatos.js"
import OeaPage from "pages/oeaPage.js"
import BlogIndexPage from "pages/BlogIndex.js"
import NoticiaPage from "pages/Noticia"

import LoginPageImageSrc from "images/demo/LoginPage.jpeg"
import AboutUsPageImageSrc from "images/demo/AboutUsPage.jpeg"
import BlogIndexPageImageSrc from "images/demo/BlogIndexPage.jpeg"
import ReclamosPage from 'pages/Reclamos'
import TarifariosPage from 'pages/Tarifario'



export const components = {
	landingPages: {
		ServiciosLandingPage: {
			component: ServiciosLandingPage,
			imageSrc: RestaurantLandingPageImageSrc,
			url: "/components/landingPages/ServiciosLandingPage",
		},
		Inicio: {
			component: Inicio,
			imageSrc: AgencyLandingPageImageSrc,
			url: "/components/landingPages/AgencyLandingPage",
		},
	},

	innerPages: {
		CotizacionPage: {
			component: CotizacionPage,
			imageSrc: LoginPageImageSrc,
			scrollAnimationDisabled: true,
			url: "/components/innerPages/CotizacionPage",
		},
		SugerenciasPage: {
			component: SugerenciasPage,
			imageSrc: LoginPageImageSrc,
			scrollAnimationDisabled: true,
			url: "/components/innerPages/SugerenciasPage",
		},
		AboutUsPage: {
			component: AboutUsPage,
			url: `/components/innerPages/AboutUsPage`,
			imageSrc: AboutUsPageImageSrc,
		},
		RegistroDatosPage: {
			component: RegistroDatosPage,
			url: `/components/innerPages/RegistroDatosPage`,
			imageSrc: AboutUsPageImageSrc,
		},
		OeaPage: {
			component: OeaPage,
			url: `/components/innerPages/OeaPage`,
			imageSrc: AboutUsPageImageSrc,
		},
		BlogIndexPage: {
			component: BlogIndexPage,
			url: `/components/innerPages/BlogIndexPage`,
			imageSrc: BlogIndexPageImageSrc,
		},
		NoticiaPage: {
			component: NoticiaPage,
			url: `/components/innerPages/NoticiaPage`,
			imageSrc: BlogIndexPageImageSrc,
		},
		ReclamosPage: {
			component: ReclamosPage,
			url: `/components/innerPages/ReclamosPage`,
			imageSrc: BlogIndexPageImageSrc,
		},
		TarifarioPage: {
			component: TarifariosPage,
			url: `/components/innerPages/TarifarioPage`,
			imageSrc: BlogIndexPageImageSrc,
		},
	},
}

export default () => {
	const { type, subtype, name } = useParams()

	try {
		let Component = null
		if (type === "blocks" && subtype) {
			Component = components[type][subtype]["elements"][name].component
			return <AnimationRevealPage disabled>
				<Component />
			</AnimationRevealPage>
		}
		else
			Component = components[type][name].component

		if (Component)
			return <Component />

		throw new Error("Componente no existe")
	}
	catch (e) {
		console.log(e)
		return <div>Error: 401 componente no encontrado</div>
	}
}
