import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import illustration from "images/email-illustration.svg";
import { TextField, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ToastAutoHide from 'components/ToastAutoHide';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;


const TextColumn = styled(Column)(props => [
	tw`md:w-7/12 mt-16 md:mt-0`,
	props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
	`background-image: url("${props.imageSrc}");`,
	tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default function ReclamosForm() {
	const [cuerpo, setCuerpo] = useState({
		nombre: "",
		apellido: "",
		domicilio: "",
		dni: "",
		telefono: "",
		email: '',
		menorEdad: "no",
		apoderado: "",
		tipoBien: "servicio",
		descripcion: "",
		tipoReclamo: 'reclamo',
		mensaje: '',
	});
	const [mensaje, setMensaje] = useState({ ident: null, message: null, type: null });

	const onChange = e => {
		setCuerpo({
			...cuerpo,
			[e.target.name]: e.target.value
		});
	};



	const emailSend = () => {
		window.emailjs.send(
			"probotector",
			"sugerencias",
			{
				"to": "mesadeayuda@gobiernodigital.gob.pe, atencionalcliente@lipaduanas.com",
				"to_name": "LIP Aduanas S.A.",
				"nombre": cuerpo.nombre,
				"apellido": cuerpo.apellido,
				"domicilio": cuerpo.domicilio,
				"dni": cuerpo.dni,
				"telefono": cuerpo.telefono,
				"email": cuerpo.email,
				"menorEdad": cuerpo.menorEdad,
				"apoderado": cuerpo.apoderado,
				"tipoBien": cuerpo.tipoBien,
				"descripcion": cuerpo.descripcion,
				"tipoReclamo": cuerpo.tipoReclamo,
				"mensaje": cuerpo.mensaje
			}).then(() => {
				setMensaje({
					ident: new Date().getTime(),
					message: 'Sugerencia enviada correctamente',
					type: 'success'
				});
				setCuerpo({
					nombre: "",
					apellido: "",
					email: '',
					mensaje: '',
					tipoReclamo: 'reclamo'
				});
			});
	};

	return (
		<Container>
			<ToastAutoHide message={mensaje} />
			<TwoColumn>
				<ImageColumn>
					<Image imageSrc={illustration} />
				</ImageColumn>
				<TextColumn textOnLeft={true}>
					<TextContent>
						<Subheading></Subheading>
						<Heading>Reclamaciones, quejas y/o reclamos.</Heading>

						{/* Sección de Datos Generales */}
						<Description>
							<strong>Razón Social:</strong> LIP ADUANAS S.A.<br />
							<strong>RUC:</strong> 20543731472 <br />
							<strong>Domicilio Fiscal:</strong> Av. Elmer Faucett Esq T Valle Nro. S/n Dpto. 204 Int. E
						</Description>

						<Grid container spacing={2} style={{ marginTop: '25px' }}>
							<Grid item xs={12}>
								<label style={{ color: '#7c8ba1', fontWeight: 'bold' }}>DATOS DEL CONSUMIDOR:</label>
							</Grid>
							<Grid item xs={12}>
								<TextField name="nombre" value={cuerpo.nombre || ''} onChange={onChange} label="Nombres" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField name="apellido" value={cuerpo.apellido || ''} onChange={onChange} label="Apellidos" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField name="email" value={cuerpo.email || ''} onChange={onChange} label="Email" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Description tw="mt-12">
								<strong>Detalle de la reclamación</strong><br />
								<div style={{ marginTop: '1rem' }}>
									<ul style={{ listStyleType: 'disc', paddingLeft: '1.5rem' }}>
										<li>
											<strong>RECLAMO:</strong> Disconformidad relacionada a los productos o servicios.
										</li>
										<li>
											<strong>QUEJA:</strong> Disconformidad NO relacionada a los productos o servicios; sino al descontento respecto a la atención al público.
										</li>
									</ul>
								</div>
							</Description>
							{/* Botones de Reclamo o Queja */}
							<Grid item xs={12}>
								<RadioGroup row name="tipoReclamo" value={cuerpo.tipoReclamo} onChange={onChange}>
									<FormControlLabel value="reclamo" control={<Radio />} label="Reclamo" />
									<FormControlLabel value="queja" control={<Radio />} label="Queja" />
								</RadioGroup>
							</Grid>
							<Grid item xs={12}>
								<TextField multiline rows={4} name='mensaje' value={cuerpo.mensaje || ''} onChange={onChange} label="Detalle del reclamo/queja" fullWidth variant='outlined' margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<SubmitButton onClick={() => emailSend()}>Enviar</SubmitButton>
							</Grid>
						</Grid>
						{/* Texto de Acciones Adoptadas */}
						<Description tw="mt-12">
							<strong>Acciones Adoptadas:</strong><br />
							Estimado Cliente, muchas gracias por registrar su incidencia en el Libro de Reclamaciones, su opinión es muy importante para nosotros.<br /><br />
							"La firma del colaborador en la presente hoja de reclamación, expresa únicamente la recepción de la misma, más no la aceptación de su contenido. La presente reclamación será tramitada dentro del plazo de ley."<br /><br />
							"Nuestra área de servicio al Cliente le informa: (i) La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI. (ii) El proveedor debe dar respuesta al reclamo o queja en un plazo no mayor a quince (15) días hábiles, el cual es improrrogable."
						</Description>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
}
