/*
*  doc: https://github.com/axios/axios
*/
import axios from 'axios'
import { BASE_URL } from 'constants/api'

// Toda API request requiere token
const AuthTokenRequest = () => {
    const axinstace = axios.create({
        baseURL: BASE_URL
    })

    // interceptor de petición (antes de solicitud)
    axinstace.interceptors.request.use(
        config => {                    // Hacer algo antes de enviar la solicitud
            const token = localStorage.getItem("auth") ?
                JSON.parse(localStorage.getItem("auth")).accessToken :
                ''
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        error => Promise.reject(error) // Hacer algo con error de solicitud
    )
    // interceptor de respuesta (despues de solicitud)
    axinstace.interceptors.response.use(
        response => response,          // Cualquier código de estado que se encuentre dentro del rango de 2xx hace que esta función se active
        error => Promise.reject(error) // Cualquier código de estado que esté fuera del rango de 2xx hace que esta función se active
    )

    return axinstace
}
export default AuthTokenRequest

// 
export const NoAuthTokenRequest = () => {

    const axinstace = axios.create({
        baseURL: BASE_URL,
    })

    return axinstace
}