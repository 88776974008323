import React, { useState, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}))

// se muestra solo cuando el identificador del mensaje cambia
const ToastAutoHide = props => {
	const classes = useStyles()
	
	const { message } = props

	const [open, setOpen] = useState(false)

	const handleClose = (event, reason) => {
		if (reason === 'clickaway')
			return
		setOpen(false)
	}

	const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />

	useEffect(() => {
		if(message.ident)
			setOpen(true)
	}, [message])

	return (
		<div className={classes.root}>
			<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={message.type}>
					{message.message}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default ToastAutoHide