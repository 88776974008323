import React from "react";

import Header from "../headers/light.js";



export default ({
	roundedHeaderButton,
	nombre = 'sam'
}) => {

	return (
		<>
			<Header roundedHeaderButton={true}/>
		</>
	);
};
