import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
// import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js"
import tw from "twin.macro"
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter"
// import Seriedad from "images/001-24-hours.svg"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons"
// import styled from "styled-components"

const Subheading = tw.span`uppercase tracking-wider text-sm`

const HeaderContainer = tw.div`justify-between max-w-screen-md mx-auto py-20 md:py-24 items-center`;
// const ContainerVid = tw.div`justify-between max-w-screen-lg mx-auto py-20 md:py-24 items-center`;
const Subheadings = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw.p`mt-4 text-justify md:text-justify text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg  w-full`;
const DireccionCallao = () => {
	return (
		<section className="content-main-container">

			<div className="flex-row">
				<div className="w-cell-12">
					<div className="group-texts">
						<p style={{ fontWeight: 'bold' }}>1. OBJETO</p>
						<br />
						<p>
							LIP ADUANAS SA es una sociedad constituida con arreglo a las leyes peruanas, es una Agencia de
							Aduana fundada en el año 2011, creada inicialmente con la finalidad de atender las operaciones
							de Importaciones y Exportaciones de nuestras empresas relacionadas, alcanzando hoy más de
							25,000 operaciones anuales. Nuestras operaciones, se enmarcan en la en la gestión especializada
							de Despachos de Aduanas, de Exportación e Importación en cada uno de los distintos regímenes
							y de carga general con servicios conexos que en conjunto generan condiciones reconocidas de
							eficiencia y seguridad en esta importante fase del Comercio Exterior que desarrolla.
						</p>
						<br />
						<p>
							LIP Aduanas S.A., ofrece conocimiento, seguridad y experiencia en cada etapa de la gestión del
							despacho de aduanas con soluciones y alternativas inmediatas a problemas y contingencias.
						</p>
						<br />
						<p>
							LIP Aduanas S.A. se encuentra en capacidad de satisfacer todas las necesidades logísticas de
							nuestros clientes, ya que contamos con la infraestructura necesaria a través de nuestras
							empresas relacionadas: NEW TRANSPORT S.A. (Operador Logístico) y LOGÍSTICA INTERMODAL
							DEL PERÚ SAC (Transporte), conformando así, una sociedad estratégica orientada a un Servicio
							Integral eficiente y seguro.
						</p>
						<br />
						<p>
							LIP ADUANAS SA está comprometida con proteger la seguridad y confidencialidad de la
							información de todas aquellas personas naturales con las que se interrelaciona, respetando
							escrupulosamente las disposiciones establecidas en la Ley No. 29733, Ley de Protección de
							Datos Personales, su Reglamento, aprobado por Decreto Supremo No. 003-2013-JUS, y sus
							normas complementarias.
						</p>
						<br />
						<p>
							El presente documento describe la Política de Protección de Datos Personales de LIP ADUANAS
							SA.
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>2. MARCO NORMATIVO</p>
						<br />
						<p>Las principales normas son las siguientes:</p>
						<br />
						<ul>
							<li>Constitución Política del Perú de 1993</li>
							<li>Ley No. 29733, Ley de Protección de Datos Personales</li>
							<li>Decreto Supremo No. 003-2013-JUS, por medio del cual se aprueba el Reglamento
								de la Ley No. 29733, Ley de Protección de Datos Personales</li>
						</ul>
						<br />
						<p style={{ fontWeight: 'bold' }}>3. ÁMBITO DE APLICACIÓN</p>
						<br />
						<p>
							La presente Política se aplica a toda actividad de tratamiento de datos personales por parte
							de LIP ADUANAS SA. En tal sentido, es de obligatorio cumplimiento para los trabajadores
							de LIP ADUANAS SA y de cualquier tercero al que LIP ADUANAS SA brinde datos personales.
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>4. COMPROMISOS DE LIP ADUANAS SA RESPECTO DE LOS DATOS PERSONALES DE TERCEROS</p>
						<br />
						<p>
							LIP ADUANAS SA considera de importancia proteger la seguridad y confidencialidad de los
							datos personales de todas las personas con las que se interrelaciona, por lo que asume los
							siguientes compromisos:
						</p>
						<br />
						<ul>
							<li>Efectuar el tratamiento de datos personales solo si cuenta con el consentimiento
								previo expreso e inequívoco del titular de dichos datos o sus representantes legales.</li>
							<li>No recopilará información personal por medios fraudulentos o ilícitos.</li>
							<li>Únicamente utilizará la información recopilada para los fines autorizados por el
								titular de los datos personales.</li>
							<li>Atenderá con agilidad, dentro de los plazos establecidos en el marco legal, las
								solicitudes para el ejercicio de los derechos ARCO que fueran presentadas por los
								titulares del dato personal o por su representante.</li>
							<li>Adoptará las medidas necesarias para asegurar la confidencialidad de los datos
								personales que le han sido proporcionados.</li>
							<li>Implementará un programa de auditoría a fin identificar oportunidades de mejora
								con relación al cumplimiento de las normas sobre protección de datos personales.</li>
							<li>Cumplir con la normativa vigente sobre protección de datos personales.</li>
						</ul>
						<br />
						<p style={{ fontWeight: 'bold' }}> 5. PRINCIPIOS</p >
						<br />
						<p>
							De conformidad con la Ley y su Reglamento, LIP ADUANAS SA se compromete a respetar los
							siguientes principios:
						</p>
						<br />
						<ul>
							<li>Principio del Consentimiento, que obliga a quien realice algún tipo de tratamiento de
								datos personales a obtener el permiso previo de su titular.</li>
							<li>Principio de Finalidad, que impone a quien recopila datos personales la obligación de
								hacerlo con una finalidad previamente determinada y el deber de ajustarse a la
								misma para cualquier actividad que involucre su tratamiento.</li>
							<li>Principio de Proporcionalidad, que obliga a que el tratamiento de datos personales
								sea relevante y no excesivo respecto de la finalidad para la cual se recopilaron.</li>
							<li>Principio de Seguridad, que obliga a la implementación de las medidas de seguridad
								necesarias para proteger la seguridad de la información personal.</li>
							<li>Principio de Calidad, que dispone que todo tratamiento de datos personales debe ser
								veraz, exacto, actualizado, necesario y pertinente.</li>
						</ul>
						<br />
						<p style={{ fontWeight: 'bold' }}> 6. FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES</p >
						<br />
						<p>
							Los datos personales recolectados por LIP ADUANAS SA serán utilizados para las siguientes
							finalidades:
						</p>
						<br />
						<ul>
							<li>Para un mejor manejo de información y comunicación entre LIP ADUANAS SA y sus
								usuarios, trabajadores y proveedores.</li>
							<li>Confirmar y corregir la información que conocemos de los usuarios, trabajadores y
								proveedores.</li>
							<li>Contar con información que permita la toma de decisiones en beneficio de los
								usuarios de servicios prestados por LIP ADUANAS SA.</li>
							<li>Permitir la evaluación de profesionales que postulen a cargos dentro de LIP
								ADUANAS SA.</li>
							<li>Dar respuesta a solicitudes, reclamos y/o quejas por parte de los usuarios.</li>
							<li>Registrar evidencias de las diversas situaciones que ocurren a lo largo de la
								infraestructura vial concesionada.</li>
							<li>Contar con información que permita determinar la identidad de las personas que
								visitan las instalaciones de LIP ADUANAS SA.</li>
						</ul>
						<br />
						<p>
							La información obtenida por LIP ADUANAS SA únicamente podrá proporcionarse a las
							entidades públicas en ejercicio de sus funciones legales o por orden judicial.
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}> 7. DERECHOS DE LOS TITULARES</p >
						<br />
						<p>
							De acuerdo con la Ley, los titulares de datos personales tienen los derechos que a
							continuación se detallan (estos se podrán ejercitar conforme al procedimiento indicado en el
							numeral 8 del presente documento):
						</p>
						<br />
						<ul>
							<li>
								Derecho de Acceso e Información:
								El titular de datos personales tiene derecho a obtener la información que sobre sí
								mismo sea objeto de tratamiento en bancos de datos de titularidad de LIP ADUANAS SA.
							</li>
							<li>
								Derecho de rectificación, actualización e inclusión:
								El titular de datos personales tiene derecho a la actualización, inclusión y rectificación de
								sus datos personales materia de tratamiento por parte de LIP ADUANAS SA cuando estos
								sean parcial o totalmente inexactos, incompletos o cuando se hubiere advertido omisión,
								error o falsedad.
							</li>
							<li>
								Derecho de Cancelación o Supresión:
								El titular de datos personales podrá solicitar la cancelación o supresión de sus datos
								personales no relacionados o necesarios para la ejecución de las obligaciones de cargo
								de LIP ADUANAS SA previstas en los contratos suscritos o las dispuestas por la normativa
								vigente.
							</li>
							<li>
								Derecho de Oposición:
								El titular de datos personales puede oponerse al tratamiento de sus datos personales en
								cualquier momento. La oposición procederá en la medida que el tratamiento no tenga
								justificación contractual o legal.
							</li>
						</ul>
						<br />
						<p style={{ fontWeight: 'bold' }}> 8. PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS ARCO</p >
						<br />
						<p>
							Cualquier interesado podrá ejercer sus derechos de Ley, para lo cual deberá seguir el
							procedimiento establecido por LIP ADUANAS SA, mediante la presentación de su DNI u
							otro documento oficial de identidad y enviando su solicitud y/o consultas a:
						</p>
						<br />
						<ul>
							<li>
								Correo electrónico: datospersonales@lipaduanas.com
							</li>
							<li>
								Dirección: Av. Elmer Faucett esquina con Tomas Valle s/n – oficina 204 E, Provincia
								Constitucional del Callao.
							</li>
						</ul>
						<br />
						<p>LIP ADUANAS SA cuenta con un Manual para el ejercicio de los Derechos ARCO que todos
							los trabajadores deben conocer y cumplir.</p>
						<br />
						<p style={{ fontWeight: 'bold' }}> 9. PROHIBICIONES</p >
						<br />
						<p>Los trabajadores, funcionarios o empleados de LIP ADUANAS SA no deberán:</p>
						<br />
						<ul>
							<li>
								Tratar los datos personales de un tercero sin obtener, previamente, el
								consentimiento del titular del dato.
							</li>
							<li>
								Utilizar los datos personales obtenidos para una finalidad distinta de por la cual
								fueron recabados.
							</li>
							<li>
								Usar la información para beneficio propio o de terceros.
							</li>
							<li>
								Compartir datos personales obtenidos por LIP ADUANAS SA sin que exista
								justificación legal o contractual para ello.
							</li>
							<li>
								Negar u obstaculizar el ejercicio de los derechos ARCO por parte de los titulares de
								los datos personales.
							</li>
							<li>
								Tratar los datos personales sin respetar los principios señalados en el numeral 5 del
								presente documento.
							</li>
							<li>
								Incumplir su deber de confidencialidad.
							</li>
						</ul>
						<br />
						<p style={{ fontWeight: 'bold' }}> 10. CONFIDENCIALIDAD</p >
						<br />
						<p>
							LIP ADUANAS SA adoptará todas las medidas necesarias para garantizar la seguridad y
							confidencialidad de la información personal que haya recopilado. Los trabajadores de LIP
							ADUANAS SA y/o cualquier tercero que tenga acceso a la información personal que trate
							LIP ADUANAS SA está obligado a mantener la confidencialidad y cumplir
							escrupulosamente el secreto profesional, de ser el caso.
						</p>
						<br />
						<p>
							Las obligaciones de confidencialidad se desarrollarán en todos los contratos que suscriba
							LIP ADUANAS SA con sus colaboradores, trabajadores, proveedores y, en general, con
							cualquier tercero.
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>11. SANCIONES</p>
						<br />
						<p>
							El incumplimiento de las disposiciones previstas por la Ley No. 29733, su Reglamento o la
							presente política, por parte de los trabajadores, será considerado como una falta grave y,
							por tanto, será susceptible de sanción de acuerdo con las normas laborales vigentes.
						</p>
					</div >
				</div >
			</div >
		</section >
	)
}

export default ({
	subheading = "",
	heading = "POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES.",
	description = <DireccionCallao />
}) => {

	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton={true} />
			<HeaderContainer>
				{subheading && <Subheadings>{subheading}</Subheadings>}
				<Heading>{heading}</Heading>
				{description && <Description>{description}</Description>}
			</HeaderContainer>
			<Footer />
		</AnimationRevealPage>
	)
}
