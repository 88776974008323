import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter"
import SugerenciasForm from "components/forms/SugerenciasForm"


export default function SugerenciasPage() {
	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton={true} />
			<SugerenciasForm />
			<Footer />
		</AnimationRevealPage>
	);
};

