import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import illustration from "images/cotizacion.svg";
import { TextField, Grid } from '@material-ui/core';
import ToastAutoHide from 'components/ToastAutoHide'

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
	tw`md:w-7/12 mt-16 md:mt-0`,
	props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
	`background-image: url("${props.imageSrc}");`,
	tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`



const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default function ContactUsForm() {
	const [cuerpo, setCuerpo] = useState({
		motivo: "Importación",
		tipoCliente: "Nacional",
		email: '',
		telefono: '',
		tipoPersona: "Natural",
		celular: '',
		dni: '',
		nombreCompleto: '',
		requerimiento: ''
	})
	const [mensaje, setMensaje] = useState({ ident: null, message: null, type: null })

	const onChange = e => {
		setCuerpo({
			...cuerpo,
			[e.target.name]: e.target.value
		})
	}

	// const emailSend = () => {
	// 	window.emailjs.send(
	// 		"probotector",
	// 		"cotización",
	// 		{
	// 			//"to": "programador@gnt.pe, shirley.guerra@lipaduanas.com, carlos.salinas@lipaduanas.com, cesar.gonzalez@newtransport.net, jorge.velasquez@lipaduanas.com, cesar.camacho@lipaduanas.com, atencionalcliente@lipaduanas.com",
	// 			//"to": "programador@gnt.pe, lipaduanas@lipaduanas.com",
	// 			"to": "abel.panta@gnt.pe",
	// 			"to_name": "LIP Aduanas S.A.",
	// 			"motivo": cuerpo.motivo,
	// 			"tipoCliente": cuerpo.tipoCliente,
	// 			"tipoPersona": cuerpo.tipoPersona,
	// 			"nombreCompleto": cuerpo.nombreCompleto,
	// 			"dni": cuerpo.dni,
	// 			"email": cuerpo.email,
	// 			"telefono": cuerpo.telefono,
	// 			"celular": cuerpo.celular,
	// 			"requerimiento": cuerpo.requerimiento
	// 		}).then(() => {
	// 			setMensaje({
	// 				ident: new Date().getTime(),
	// 				message: 'Gracias por su requerimiento.\nSu solicitud será atendida en las próximas 48 horas.\nAtentamente\nAtención al Cliente\nLIP ADUANAS S.A',
	// 				type: 'success'
	// 			})
	// 			setCuerpo({
	// 				motivo: "Importación",
	// 				tipoCliente: "Nacional",
	// 				email: '',
	// 				telefono: '',
	// 				tipoPersona: "Natural",
	// 				celular: '',
	// 				dni: '',
	// 				nombreCompleto: '',
	// 				requerimiento: ''
	// 			})
	// 		})
	// }

	const emailSend = () => {
		window.emailjs.send(
			"probotector",
			"cotización",
			{
				"to": "programador@gnt.pe, lipaduanas@lipaduanas.com",
				"to_name": "LIP Aduanas S.A.",
				"motivo": cuerpo.motivo,
				"tipoCliente": cuerpo.tipoCliente,
				"tipoPersona": cuerpo.tipoPersona,
				"nombreCompleto": cuerpo.nombreCompleto,
				"dni": cuerpo.dni,
				"email": cuerpo.email,
				"telefono": cuerpo.telefono,
				"celular": cuerpo.celular,
				"requerimiento": cuerpo.requerimiento
			}).then(() => {
				setMensaje({
					ident: new Date().getTime(),
					message: 'Gracias por su requerimiento.\nSu solicitud será atendida en las próximas 48 horas.',
					type: 'success'
				});
				setCuerpo({
					motivo: "Importación",
					tipoCliente: "Nacional",
					email: '',
					telefono: '',
					tipoPersona: "Natural",
					celular: '',
					dni: '',
					nombreCompleto: '',
					requerimiento: ''
				});

				// Enviar el POST a la API
				fetch('https://correo-ggcf.onrender.com/correo/confirmacion', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ to: cuerpo.email }),
				})
					.then(response => {
						if (!response.ok) {
							throw new Error('Error en la solicitud a la API');
						}
						return response.json();
					})
					.then(data => {
						console.log('Éxito en el envío a la API:', data);
					})
					.catch(error => {
						console.error('Error al enviar a la API:', error);
					});
			})
			.catch(error => {
				console.error('Error al enviar el correo:', error);
			});
	}


	return (
		<Container>
			<ToastAutoHide message={mensaje} />
			<TwoColumn>
				<ImageColumn>
					<Image imageSrc={illustration} />
				</ImageColumn>
				<TextColumn textOnLeft={true}>
					<TextContent>
						<Subheading>Cotización</Subheading>
						<Heading><>Dinos cuál <span tw="text-blue-500">es tu</span><wbr /> requerimiento.</></Heading>
						<Description></Description>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									select
									fullWidth
									name='motivo'
									value={cuerpo.motivo || 'Importación'}
									onChange={onChange}
									variant='outlined'
									margin='normal'
									label="Motivo de contacto"
									SelectProps={{
										native: true,
									}}
								>
									<option key='1' value='Importación'>Importación</option>
									<option key='2' value='Exportación'>Exportación</option>
									<option key='3' value='Importación / Exportación'>Importación / Exportación</option>
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									name="email"
									label="Email"
									fullWidth
									value={cuerpo.email || ''}
									onChange={onChange}
									variant='outlined'
									margin='normal' />
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									select
									fullWidth
									name='tipoCliente'
									value={cuerpo.tipoCliente || "Nacional"}
									onChange={onChange}
									variant='outlined'
									margin='normal'
									label="Tipo de cliente"
									SelectProps={{
										native: true,
									}}
								>
									<option key='1' value='Nacional'>Nacional</option>
									<option key='2' value='Extranjero'>Extranjero</option>
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									name="telefono"
									value={cuerpo.telefono || ''}
									onChange={onChange}
									label="Teléfono"
									fullWidth
									variant='outlined'
									margin='normal' />
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									select
									fullWidth
									name='tipoPersona'
									value={cuerpo.tipoPersona || "Nacional"}
									onChange={onChange}
									variant='outlined'
									margin='normal'
									label="Tipo de persona"
									SelectProps={{
										native: true,
									}}
								>
									<option key='1' value='Natural'>Natural</option>
									<option key='2' value='Jurídica'>Jurídica</option>
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									name="celular"
									value={cuerpo.celular || ''}
									onChange={onChange}
									label="Celular"
									fullWidth
									variant='outlined'
									margin='normal' />
							</Grid>
							<Grid item xs={6}>
								<TextField
									name="dni"
									label="DNI*"
									value={cuerpo.dni || ''}
									onChange={onChange}
									fullWidth
									variant='outlined'
									margin='normal' />
							</Grid>
							<Grid item xs={6}>
								<TextField
									name="nombreCompleto"
									value={cuerpo.nombreCompleto || ''}
									onChange={onChange}
									label="Nombre completo"
									fullWidth
									variant='outlined'
									margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<TextField
									multiline
									name='requerimiento'
									value={cuerpo.requerimiento || ''}
									onChange={onChange}
									rows={4}
									label="Requerimiento"
									fullWidth
									variant='outlined'
									margin='normal' />
							</Grid>
							<Grid item xs={12}>
								<SubmitButton onClick={() => emailSend()}>Enviar</SubmitButton>
							</Grid>
						</Grid>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
};


