import React from "react";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, withStyles, Chip } from "@material-ui/core";
import tw from "twin.macro";
import { SectionHeading } from "./Headings";

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-500 `;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#4299e1',
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,
    },
}))(TableRow);

const Heading = tw(SectionHeading)`mt-16 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const HighlightedSpan = tw.span`text-blue-500`;
const Parrafo = tw.p`text-blue-700 mb-5 text-left font-bold `;

// Estilo personalizado para el Chip
const StyledChip = withStyles(() => ({
    root: {
        backgroundColor: '#49b5d4',
        color: 'white',
        fontWeight: 'bold',
        marginLeft: '8px',
        borderRadius: '4px',
        padding: '4px 0px',
    },
}))(Chip);

export default function TarifarioTable() {
    const rows = [
        { servicio: "AGENCIAMIENTO DE ADUANAS", tipoComercio: "EXPORTACIÓN", tipoCarga: "CARGA GENERAL", precio: "70.00", unidad: "CONTENEDOR", moneda: "DÓLARES AMERICANOS", afectoIgv: true },
        { servicio: "AFORO FISICO", tipoComercio: "EXPORTACIÓN", tipoCarga: "CARGA GENERAL", precio: "25.00", unidad: "CONTENEDOR", moneda: "DÓLARES AMERICANOS", afectoIgv: true },
        { servicio: "PRECINTO DE ADUANA", tipoComercio: "EXPORTACIÓN", tipoCarga: "CARGA GENERAL", precio: "5.00", unidad: "PRECINTO", moneda: "DÓLARES AMERICANOS", afectoIgv: true },
        { servicio: "AGENCIAMIENTO DE ADUANAS", tipoComercio: "IMPORTACIÓN", tipoCarga: "CARGA GENERAL", precio: "0.60%", unidad: "% VALOR CIF", moneda: "DÓLARES AMERICANOS", afectoIgv: true },
        { servicio: "GASTOS OPERATIVOS", tipoComercio: "IMPORTACIÓN", tipoCarga: "CARGA GENERAL", precio: "50.00", unidad: "EMBARQUE", moneda: "DÓLARES AMERICANOS", afectoIgv: true },
        { servicio: "PRECINTO DE ADUANA", tipoComercio: "IMPORTACIÓN", tipoCarga: "CARGA GENERAL", precio: "5.00", unidad: "PRECINTO", moneda: "DÓLARES AMERICANOS", afectoIgv: true },
        { servicio: "TRAMITE DOCUMENTARIO", tipoComercio: "IMPORTACIÓN", tipoCarga: "CARGA GENERAL", precio: "50.00", unidad: "EMBARQUE", moneda: "DÓLARES AMERICANOS", afectoIgv: true },
    ];

    return (
        <Container maxWidth="lg" style={{ marginBottom: '80px' }}>
            <Heading><>Tarifario <HighlightedSpan> LIP Aduanas S.A.</HighlightedSpan></></Heading>
            <br />
            <Parrafo>TARIFAS APLICABLES PARA TODAS NUESTRAS SEDES: CALLAO - PAITA - PISCO - SALAVERRY - CHICLAYO - CHIMBOTE - CHANCAY(*)</Parrafo>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: '30%' }}>DENOMINACIÓN DEL SERVICIO</StyledTableCell>
                            <StyledTableCell>TIPO DE COMERCIO</StyledTableCell>
                            <StyledTableCell>TIPO DE CARGA</StyledTableCell>
                            <StyledTableCell>PRECIO (SIN IGV)</StyledTableCell>
                            <StyledTableCell>UNIDAD DE COBRO</StyledTableCell>
                            <StyledTableCell>MONEDA</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <StyledTableRow key={index}>
                                <TableCell style={{ width: '30%' }}>{row.servicio}</TableCell>
                                <TableCell>{row.tipoComercio}</TableCell>
                                <TableCell>{row.tipoCarga}</TableCell>
                                <TableCell style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {row.precio}
                                    <StyledChip
                                        label={row.afectoIgv ? "Afecto IGV" : "Sin Afecto IGV"}
                                        size="small"
                                    />
                                </TableCell>
                                <TableCell>{row.unidad}</TableCell>
                                <TableCell>{row.moneda}</TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Description tw="mt-12">
                <strong style={{ color: '#2b6cb0', fontSize:'18px' }}>(*) NOTA IMPORTANTE:</strong><br />
                Tarifa aplicable desde el inicio de operaciones en la jurisdicción de Chancay.
            </Description>
        </Container>
    );
}
