import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
// import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js"
import tw from "twin.macro"
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter"
import MainFeature1 from "components/features/TwoColWithButton.js"
import Features from "components/features/ThreeColSimple.js"
import FAQ from "components/faqs/SimpleWithSideImage"
import mision from 'assets/images/mision.jpg'
import vision from 'assets/images/vision.jpg'
import oea from 'assets/images/oea.jpg'
import Calificado from "images/026-customer-service.svg"
import Honestidad from "images/021-box-5.svg"
import Equipo from "images/015-cargo-2.svg"
// import Seriedad from "images/001-24-hours.svg"
import Respeto from "images/011-call-center-1.svg"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons"
import { useHistory } from 'react-router-dom'
// import styled from "styled-components"

const Subheading = tw.span`uppercase tracking-wider text-sm`

const HeaderContainer = tw.div`justify-between max-w-screen-md mx-auto py-20 md:py-24 items-center`;
// const ContainerVid = tw.div`justify-between max-w-screen-lg mx-auto py-20 md:py-24 items-center`;
const Subheadings = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw.p`mt-4 text-justify md:text-justify text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg  w-full`;

const DireccionCallao = () => {
	return (
		<>
			LIP Aduanas S.A. es una Agencia de Aduana fundada en el año 2011, creada inicialmente con la finalidad de atender las operaciones de Importaciones y Exportaciones de nuestras empresas relacionadas, alcanzando hoy más de 25,000 operaciones anuales. Nuestras operaciones, se enmarcan en la en la gestión especializada de Despachos de Aduanas, de Exportación e Importación en cada uno de los distintos regímenes y de carga general con servicios conexos que en conjunto generan condiciones reconocidas de eficiencia y seguridad en esta importante fase del Comercio Exterior que desarrolla.<br /><br />
			LIP Aduanas S.A., ofrece conocimiento, seguridad y experiencia en cada etapa de la gestión del despacho de aduanas con soluciones y alternativas inmediatas a problemas y contingencias. <br /><br />
			LIP Aduanas S.A. se encuentra en capacidad de satisfacer todas las necesidades logísticas de nuestros clientes, ya que contamos con la infraestructura necesaria a través de nuestras empresas relacionadas: NEW TRANSPORT S.A. (Operador Logístico) y LOGÍSTICA INTERMODAL DEL PERÚ SAC (Transporte), conformando así, una sociedad estratégica orientada a un Servicio Integral eficiente y seguro.<br /><br />
		</>
	)
}

// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

export default ({
	subheading = "",
	heading = "LIP Aduanas S.A.",
	description = <DireccionCallao />
}) => {
	const history = useHistory()
	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton={true} />
			<HeaderContainer>
				{subheading && <Subheadings>{subheading}</Subheadings>}
				<Heading>{heading}</Heading>
				{description && <Description>{description}</Description>}
			</HeaderContainer>
			<MainFeature1
				subheading={<Subheading>LIP Aduanas S.A.</Subheading>}
				heading="Nuestra Misión"
				buttonRounded={false}
				primaryButtonText="See Portfolio"
				imageSrc={mision}
				description="Contribuir en forma activa y responsable, al desarrollo integral de nuestros clientes, sociedad y talento humano, a través de una práctica profesional diferenciada en el servicio de agenciamiento de aduanas que realizamos, generadora de un valor sostenible, bajo criterios de optimización, confiabilidad y continuidad que son la base de nuestra cultura organizacional."
			/>
			<MainFeature1
				subheading={<Subheading>LIP Aduanas S.A.</Subheading>}
				heading="Nuestra Visión"
				buttonRounded={false}
				primaryButtonText="Contact Us"
				imageSrc={vision}
				textOnLeft={false}
				description="Ser reconocidos como una agencia de aduanas líder en la prestación de servicios logísticos a nivel nacional e internacional, donde la fortaleza de nuestra capacidad profesional, técnica y tecnológica, nuestra respuesta ágil e innovadora y nuestro comportamiento ético, seguro y responsable, nos posicionen como la mejor empresa en el sector, logrando satisfacer los requerimientos de nuestros clientes y asociados de negocios."
			/>
			<Features
				subheading={<Subheading>LIP Aduanas S.A.</Subheading>}
				heading="Nuestros Valores"
				description=""
				cards={[
					{
						imageSrc: Calificado,
						title: "Responsabilidad.",
						description: "Brindamos respuesta inmediata y eficiente a los pedidos o requerimientos de nuestros clientes."
					},
					{
						imageSrc: Equipo,
						title: "Trabajo en equipo.",
						description: "Cada colaborador de nuestro equipo desempeña un rol concreto, en función de sus fortalezas que, junto al resto, mejoran los resultados finales."
					},
					{
						imageSrc: Honestidad,
						title: "Actitud de servicio.",
						description: "Damos soluciones a problemas en el momento adecuado y seguimiento continuo a los servicios brindados."
					},
					{
						imageSrc: Respeto,
						title: "Transparencia",
						description: "Es uno de nuestros objetivos para incrementar y asegurar el desempeño organizacional, generando confianza y credibilidad ante nuestros clientes."
					}
				]}
				linkText=""
			/>
			{/* <ContainerVid>
				<Heading>Nuestro equipo de trabajo</Heading>
				<Description>
					<StyledResponsiveVideoEmbed
						url="/files/equipo.mp4?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
						background="transparent"
					/>
				</Description>
			</ContainerVid> */}
			<MainFeature1
				onClick={() => alert('hola')}
				subheading={<Subheading>Aplicando a</Subheading>}
				heading="Compromiso de seguridad."
				buttonRounded={false}
				primaryButtonText="Contact Us"
				imageSrc={oea}
				textOnLeft={false}
				description={<PrimaryButton onClick={() => history.push(`/components/innerPages/OeaPage`)}>Ver más</PrimaryButton>}
			/>
			<FAQ />
			<Footer />
		</AnimationRevealPage>
	)
}
