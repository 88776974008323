import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter"
import TarifarioForm from "components/forms/TarifarioForm";


export default function TarifariosPage() {
	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton={true} />
			<TarifarioForm />
			<Footer />
		</AnimationRevealPage>
	);
};

