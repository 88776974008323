import React, { useState, useEffect } from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import Footer from "components/footers/MiniCenteredFooter"
import tw from "twin.macro"
import styled from "styled-components"
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light"
import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg"
import AuthTokenRequest from 'helpers/axiosInstances'
import { formatDate } from 'helpers/utils'
import ReadOnly from 'components/ReadOnly'

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;


const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Body = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;


const ContentNoticia = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
])

export default function NoticiaPage() {
    const id = window.location.search.split('=')[1]
    const [noticia, setNoticia] = useState({})
    const [readOnlyText, setReadOnlyText] = useState([
        {
            type: "paragraph",
            children: [
                { text: "Contenido " }
            ]
        }
    ])

    const getNoticia = () => {
        const onGetNoticia = async () => await AuthTokenRequest().get(`/news/get?id=${id}`)
            .then(resp => {
                setNoticia(resp.data)
                setReadOnlyText(JSON.parse(resp.data.content))
            })
        onGetNoticia()
    }

    useEffect(getNoticia, [])

    const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
    background-image: url(${noticia.thumbnailSrc});
  `;

    const Image = styled.div(() => [
        `background-image: url("${noticia.thumbnailSrc}");`,
        tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
    ])

    const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
    const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
    const Title = tw.h4`text-3xl font-bold text-gray-900`;
    const Description = tw.p`mt-2 text-sm leading-loose text-justify`;

    const SvgDotPattern1 = tw(
        SvgDotPatternIcon
    )`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
    const SvgDotPattern2 = tw(
        SvgDotPatternIcon
    )`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
    const SvgDotPattern3 = tw(
        SvgDotPatternIcon
    )`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
    const SvgDotPattern4 = tw(
        SvgDotPatternIcon
    )`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

    return (
        <AnimationRevealPage disabled>
            <Container>
                <OpacityOverlay />
                <HeroContainer>
                    <StyledHeader roundedHeaderButton={true} />
                    <Content>
                        <Heading>{noticia.title}</Heading>
                    </Content>
                </HeroContainer>
            </Container>
            <Body>
                <SingleColumn>
                    <ContentNoticia>
                        <Card>
                            <Image imageSrc={noticia.thumbnailSrc} />
                            <Details>
                                <Title>{formatDate(noticia.date)}</Title>
                                <Subtitle>{noticia.location}</Subtitle>
                                <Description>
                                    <ReadOnly initialValue={readOnlyText} />
                                </Description>
                            </Details>
                        </Card>
                    </ContentNoticia>
                </SingleColumn>
                <SvgDotPattern1 />
                <SvgDotPattern2 />
                <SvgDotPattern3 />
                <SvgDotPattern4 />
            </Body>
            <Footer />
        </AnimationRevealPage>
    )
}
