import React, { useState, useEffect } from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import { Container, ContentWithPaddingXl } from "components/misc/Layouts"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter"
import { SectionHeading } from "components/misc/Headings"
import { PrimaryButton } from "components/misc/Buttons"
import AuthTokenRequest from 'helpers/axiosInstances'
import { formatDate } from 'helpers/utils'

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
		props.principal &&
		css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
	headingText = "Normas legales",
	headingText2 = " Noticias",

}) => {
	const [visible, setVisible] = useState(13)
	const [news, setNews] = useState([])
	const filter = {
		page: 1,
		rows: 20,
		id: 0,
		title_tb: 0,
		title: "",
		shortDesc_tb: 0,
		shortDesc: "",
		sortcolumn: "",
		sortorder: ""
	}
	const onLoadMoreClick = () => {
		setVisible(v => v + 12)
	}

	useEffect(() => {
		const fetchNoticias = async () => {
			const { data } = await AuthTokenRequest().post('/news/examine', filter)
			setNews(data.items === null ? [] : data.items)
		}
		fetchNoticias()
	}, [])


	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton={true} />
			<Container>
				<ContentWithPaddingXl>
					<HeadingRow>
						<Heading>{headingText}</Heading>
					</HeadingRow>
					<hr></hr>
					{/* Enlaces pdf de google drive */}
					<div>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1w6UyP64i64RMfiejB3R6lybstXo8I6-p/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								RESOLUCIÓN DE SUPERINTENDENCIA QUE MODIFICA LAS RESOLUCIONES DE SUPERINTENDENCIA N° 000131-2022/SUNAT Y N° 000171-2022/SUNAT Y EL PROCEDIMIENTO ESPECÍFICO "INSPECCIÓN NO INTRUSIVA DE MERCANCÍA” CONTROL-PE.00.10 (VERSION 1)
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1B8nziuuBcS4J5S97UXo631e4pXpwV_xH/view?usp=sharing" target="_blank" rel="noopener noreferrer">
							RESOLUCIÓN QUE APRUEBA EL PROCEDIMIENTO ESPECÍFICO “APLICACIÓN
								DE PREFERENCIAS ARANCELARIAS AL AMPARO DEL ACUERDO COMERCIAL
								ENTRE LA UNIÓN EUROPEA Y SUS ESTADOS MIEMBROS, POR UNA PARTE, Y
								COLOMBIA, EL PERÚ Y ECUADOR, POR OTRA” DESPA-PE.01.31 (VERSIÓN 2)  
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1fSb5E3MkrH447kNK-p0k5RqK4FF22kBJ/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								RESOLUCIÓN DE SUPERINTENDENCIA QUE MODIFICA LAS RESOLUCIONES
								DE SUPERINTENDENCIA N° 000131-2022/SUNAT Y N° 000171-2022/SUNAT Y EL
								PROCEDIMIENTO ESPECÍFICO "INSPECCIÓN NO INTRUSIVA DE MERCANCÍA”
								CONTROL-PE.00.10 (VERSION 1)
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1w4xFV9QT0oJ9PL_iZFQSdjsAtTdKcFqj/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								APRUEBAN EL PORCENTAJE REQUERIDO PARA DETERMINAR EL LÍMITE
								MÁXIMO DE DEVOLUCIÓN DEL IMPUESTO SELECTIVO AL CONSUMO A QUE SE
								REFIERE EL REGLAMENTO DEL DECRETO DE URGENCIA N.o 012-2019
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/17umuKYucQ_ohfOSjFPlHiPWp7q5TLNqq/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								RESOLUCIÓN DE SUPERINTENDENCIA QUE MODIFICA LA RESOLUCIÓN DE
								SUPERINTENDENCIA N.° 210-2004/SUNAT MEDIANTE LA CUAL SE APRUEBAN
								LAS DISPOSICIONES REGLAMENTARIAS DEL DECRETO LEGISLATIVO N.° 943
								QUE APROBÓ LA LEY DEL REGISTRO ÚNICO DE CONTRIBUYENTES
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1hN0uXrgOzzmAEhZvPdleZBDTJZM-tc4x/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								MODIFICAN EL ANEXO II “REQUISITOS SANITARIOS PARA LA IMPORTACIÓN DE POLLITOS RECIÉN NACIDOS PROCEDENTES DE LOS ESTADOS UNIDOS DE AMÉRICA” DE LA RESOLUCIÓN DIRECTORAL N° 0002-2014-MINAGRI-SENASA-DSA
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1RJ5ruPvAGw_NWBvQFWGe5oWvqnxpeOH5/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								APRUEBAN NUEVO FORMULARIO PARA LA COMUNICACIÓN DE OPERACIONES INUSUALES A QUE SE REFIERE EL ARTÍCULO 76 DEL REGLAMENTO DEL DECRETO LEGISLATIVO NO 1126
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1rPk28_rHK0T8ertS8rMYvbWH-okumFRf/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								ESTABLECEN MEDIDAS Y FACILIDADES PARA EL OPERADOR ECONÓMICO AUTORIZADO POR PARTE DE LA SUCAMEC
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1W7ugy4U9Wdh4IMAdJVuAQZINZEfX0ISK/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								APRUEBAN LISTADO DE ENTIDADES QUE PODRÁN SER EXCEPTUADAS DE LA PERCEPCIÓN DEL IMPUESTO GENERAL A LAS VENTAS
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1Dw6lx8LwaONmXLGWKoS9aCsT8fE7j5_t/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								DISPONEN LA NO APLICACIÓN DE LA MEDIDA DE SALVAGUARDIA DEFINITIVA SOBRE LAS IMPORTACIONES DE CONFECCIONES
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1iEH3UqsD8k7iGKaWgsfTw9jHyrBI1aJy/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								RESOLUCIÓN DE SUPERINTENDENCIA
								QUE APRUEBA EL PROCEDIMIENTO
								“INMOVILIZACIÓN - INCAUTACIÓN Y
								DETERMINACIÓN LEGAL DE MERCANCÍAS”
								CONTROL-PE.00.01 (VERSIÓN 8), MODIFICA EL
								PROCEDIMIENTO “EJECUCIÓN DE ACCIONES
								DE CONTROL EXTRAORDINARIO” CONTROL-
								PG.02 (VERSIÓN 1) Y DEROGA EL INSTRUCTIVO
								“CONFECCIÓN, LLENADO Y REGISTRO DEL
								ACTA DE INMOVILIZACIÓN - INCAUTACIÓN”
								CONTROL-IT.00.01 (VERSIÓN 2)
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/17tTlRynvLtNaqlPuDXkB90FkNbcOBeZB/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								MODIFICAN LA NORMA PARA LA PREVENCIÓN DEL LAVADO DE ACTIVOS Y DEL FI NANCIAMIENTO DEL TERRORISMO APLICABLE A LOS SUJETOS OBLIGADOS BAJO SUPERVISIÓN DE LA UIF-PERÚ, EN MATERIA DE PREVENCIÓN DEL LAVADO DE ACTIVOS Y DEL FI NANCIAMIENTO DEL TERRORISMO
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/19wmf5TcWjlodU_ksu4lTagpNl5Qw1ISG/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								MODIFICAN LA DIRECTIVA 001-2013/TRI- INDECOPI, RÉGIMEN DE NOTIFICACIÓN DE ACTOS ADMINISTRATIVOS Y OTRAS COMUNICACIONES EMITIDAS EN LOS PROCEDIMIENTOS ADMINISTRATIVOS A CARGO DE LOS ÓRGANOS RESOLUTIVOS DEL INDECOPI
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1XpGExea7pE_8Akll6lS9VDp5iOnWIyhA/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								DISPONEN LA PUBLICACIÓN DE PROYECTO DE DECRETO SUPREMO QUE ESTABLECE DISPOSICIONES PARA LA OPERACIÓN DEL OBSERVATORIO LOGÍSTICO DE COMERCIO EXTERIOR
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1z8HcHkcjUInRilDxgEepFAUBytCU79mv/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								CONFIRMAN LA RES. N° 182-2021/CDB- INDECOPI, MEDIANTE LA CUAL LA COMISIÓN DE DUMPING, SUBSIDIOS Y ELIMINACIÓN DE BARRERAS COMERCIALES NO ARANCELARIAS RESOLVIÓ MANTENER LA VIGENCIA DE LOS DERECHOS ANTIDUMPING DEFINITIVOS APLICADOS A LAS IMPORTACIONES DE BIODIÉSEL (B100) ORIGINARIO DE LA REPÚBLICA DE ARGENTINA, IMPUESTOS MEDIANTE RES. N° 189-2016/CDB- INDECOPI Y CONFIRMADA POR RES. N° 145- 2018/SDC-INDECOPI
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1CtwM7oX1h_-Py9jqQDOf3kNojO_yGKKJ/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								MODIFICAN EL REGLAMENTO DE LA LEY DEL IMPUESTO GENERAL A LAS VENTAS E IMPUESTO SELECTIVO AL CONSUMO
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1f31yAZlquo4m59lXJomHHzuthdO01vuf/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								LEY QUE APRUEBA LA REDUCCIÓN
								TEMPORAL DE COSTOS DE ADQUISICIÓN
								DE INSUMOS IMPORTADOS RELEVANTES
								PARA LA ECONOMÍA NACIONAL
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/1sAMnS846lTIEpbdWSPu-LLgcI8boPKKf/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								APRUEBAN EL “PROCEDIMIENTO PARA LA PRESENTACIÓN Y ATENCIÓN DE QUEJAS Y SUGERENCIAS DE LA SUNAT”
							</a>
						</li>
						<br></br>
						<li>
							<a href="https://drive.google.com/file/d/127srdW6Fg-t5UJ4GRTU2vPpa2WJWOAKe/view?usp=sharing" target="_blank" rel="noopener noreferrer">
								APRUEBAN EL PROCEDIMIENTO GENERAL
								“CERTIFICACIÓN DEL OPERADOR ECONÓMICO AUTORIZADO”
								DESPA-PG.29 (VERSIÓN 3)
							</a>
						</li>
						<br></br>
						<br></br>
						<br></br>
					</div>
					<HeadingRow>
						<Heading>{headingText2}</Heading>
					</HeadingRow>
					<hr></hr>
					<Posts>
						{news.slice(0, visible).map((noticia, index) => (
							<PostContainer key={index} principal={noticia.principal}>
								<Post className="group" as="a" href={`/components/innerPages/NoticiaPage?id=${noticia.id}`}>
									<Image imageSrc={noticia.imageSrc} />
									<Info>
										<Category>Noticia</Category>
										<CreationDate>{formatDate(noticia.date)}</CreationDate>
										<Title>{noticia.title}</Title>
										{noticia.principal && noticia.shortDesc && <Description>{noticia.shortDesc}</Description>}
									</Info>
								</Post>
							</PostContainer>
						))}
					</Posts>
					{visible < news.length && (
						<ButtonContainer>
							<LoadMoreButton onClick={onLoadMoreClick}>Ver más</LoadMoreButton>
						</ButtonContainer>
					)}
				</ContentWithPaddingXl>
			</Container>
			<Footer />
		</AnimationRevealPage >
	)
}