import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter"
import ReclamacionesForm from "components/forms/ReclamosForm"


export default function ReclamosPage() {
	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton={true} />
			<ReclamacionesForm />
			<Footer />
		</AnimationRevealPage>
	);
};

