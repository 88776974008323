import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter"
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-justify text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Compromiso de Seguridad" }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              En la búsqueda de obtener la Certificación OEA, las empresas deben considerar medidas de protección y seguridad integral, con las que puedan controlar el acceso a instalaciones e información sensible, trazabilidad, evaluación de asociados de negocio (proveedores y clientes) y monitoreo del personal.
            </p>

            <h2>Asimismo, es importante resaltar algunos de los beneficios obtenidos para la operatividad:</h2>
            <p></p>
            <ul>
              <li>Protección y aseguramiento de las mercancías.</li>
              <li>Información constante sobre el estado de ubicación de las mercancías.</li>
              <li>Revisiones de carga No Intrusivas.</li>
              <li>Priorización de atención dentro de las listas de espera.</li>
              <li>Reducción de inspecciones y manipulación de la carga.</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
