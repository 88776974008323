import React from "react";
import tw from "twin.macro";
import TarifarioTable from "components/misc/TarifarioTable";

const Container = tw.div`relative`;

export default function TarifarioForm() {

	return (
		<Container>
			<TarifarioTable />
		</Container>
	);
}
