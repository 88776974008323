import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";


export default function CotizacionPage() {

	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton={true} />
			<ContactUsForm />
			<Footer />
		</AnimationRevealPage>
	);
};

