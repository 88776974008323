import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import tw from "twin.macro"
import styled from "styled-components"
import { SectionHeading } from "components/misc/Headings"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons"
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg"
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg"
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg"
import { useHistory } from 'react-router-dom'
import AuthTokenRequest from 'helpers/axiosInstances'

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
	`background-image: url("${props.imageSrc}");`,
	tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm text-justify leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
	// useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
	const [sliderRef, setSliderRef] = useState(null)
	const [news, setNews] = useState([])
	const filter = {
		page: 1,
		rows: 40,
		id: 0,
		title_tb: 0,
		title: "",
		shortDesc_tb: 0,
		shortDesc: "",
		sortcolumn: "",
		sortorder: ""
	}
	const history = useHistory()
	const sliderSettings = {
		arrows: false,
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 2,
				}
			},

			{
				breakpoint: 900,
				settings: {
					slidesToShow: 1,
				}
			},
		]
	}


	useEffect(() => {
		const fetchNoticias = async () => {
			const { data } = await AuthTokenRequest().post('/news/examine', filter)
			setNews(data.items === null ? [] : data.items)
		}
		fetchNoticias()
	}, [])

	return (
		<Container>
			<Content>
				<HeadingWithControl>
					<Heading>Noticias</Heading>
					<Controls>
						<PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon /></PrevButton>
						<NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon /></NextButton>
					</Controls>
				</HeadingWithControl>
				<CardSlider ref={setSliderRef} {...sliderSettings}>
					{news.map((noticia, index) => (
						<Card key={index}>
							<CardImage imageSrc={noticia.imageSrc} />
							<TextInfo>
								<TitleReviewContainer>
									<Title>{noticia.title}</Title>
								</TitleReviewContainer>
								<SecondaryInfoContainer>
									<IconWithText>
										<IconContainer>
											<LocationIcon />
										</IconContainer>
										<Text>{noticia.location}</Text>
									</IconWithText>
								</SecondaryInfoContainer>
								<Description>{noticia.shortDesc}</Description>
							</TextInfo>
							<PrimaryButton onClick={() => history.push(`/components/innerPages/NoticiaPage?id=${noticia.id}`)}>Ver más</PrimaryButton>
						</Card>
					))}
				</CardSlider>
			</Content>
		</Container>
	);
};
