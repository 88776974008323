import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import banner1 from 'assets/images/banner1.jpg'
import banner1768 from 'assets/images/banner1768.jpg'
import banner2 from 'assets/images/banner2.jpg'
import banner2768 from 'assets/images/banner2768.jpg'
import banner3 from 'assets/images/banner3.jpg'
import banner3768 from 'assets/images/banner3768.jpg'
import libroReclamaciones from 'assets/images/libroReclamaciones.jpg'
import './slickNew.scss'
import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js"
import { useWindowDimensions } from 'helpers/useWindowDimensions'

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;


const NavLink = tw(NavLinkBase)`
	sm:text-sm sm:mx-6 sm:text-black lg:text-white md:text-white
`;


const settings = {
	dots: true,
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	lazyLoad: true,
	adaptiveHeight: true,
	adaptiveWidth: true,
	arrows: true,
	autoplaySpeed: 5000,
	className: 'slides'
}

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8`}
  width: '100%';
`;
// const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;


const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  ${tw`bg-transparent bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

const LibroReclamacionesImage = styled.img`
	width: 90px;
	position: absolute;
	display: inline-block;
	margin-left: 770px;
	margin-top: -35px;	

	/* Para resoluciones de 1366x768 */
	@media (max-width: 1366px) {
	  width: 70px;
	  margin-left: 755px;
	  margin-top: -30px;
	}
	/* Para resoluciones de 1280x720 */
	@media (max-width: 1280px) {
	  width: 65px;
	  margin-left: 760px;
	  margin-top: -30px;
	}
	/* Para resoluciones móviles o menores a 768px */
	@media (max-width: 768px) {
	  width: 60px;
	  margin-left: -30px;
	  margin-top: 0px;
	}
`;

export default ({
	navLinks = [
		<NavLinks key={1}>
			<NavLink href="/">
				Inicio
			</NavLink>
			<NavLink href="/components/innerPages/AboutUsPage">
				Nosotros
			</NavLink>
			<NavLink href="/components/landingPages/ServiciosLandingPage">
				Servicios
			</NavLink>
			<NavLink href="/components/innerPages/BlogIndexPage">
				Noticias
			</NavLink>
			<NavLink href="/components/innerPages/CotizacionPage">
				Cotización
			</NavLink>
			<NavLink href="https://lip.sintad.pe/Login/index " target='_blank'>
				Tracking
			</NavLink>
			<NavLink href="/components/innerPages/TarifarioPage">
				Tarifario
			</NavLink>
			<NavLink href="/components/innerPages/ReclamosPage">
				<LibroReclamacionesImage
					src={libroReclamaciones}
					alt="Imagen del Libro de Reclamaciones"
				/>
			</NavLink>
		</NavLinks>
	]
}) => {
	const { width } = useWindowDimensions()

	const Banner1 = styled.div`
  ${tw`relative -mx-1 -mt-1 bg-center bg-cover h-screen min-h-144 lg:flex-row`}
  background-image: url(${width <= 768 ? banner1768 : banner1});
`;

	const Banner2 = styled.div`
  ${tw`relative -mx-1 -mt-1 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${width <= 768 ? banner2768 : banner2});
`;

	const Banner3 = styled.div`
  ${tw`relative -mx-1 -mt-1 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${width <= 768 ? banner3768 : banner3});
`;


	return (
		<Container>
			<Slider  {...settings}>
				<Banner1>
					<LeftColumn>
						<StyledHeader key={1} links={navLinks} collapseBreakpointClass="md" />
						<Content>
							<Actions>

							</Actions>
						</Content>
					</LeftColumn>

					<RightColumn />
				</Banner1>
				<Banner2>
					<LeftColumn>
						<StyledHeader key={2} links={navLinks} collapseBreakpointClass="md" />
						<Content>
							<Actions>

							</Actions>
						</Content>
					</LeftColumn>

					<RightColumn />
				</Banner2>
				<Banner3>
					<LeftColumn>
						<StyledHeader key={3} links={navLinks} collapseBreakpointClass="md" />
						<Content>
							<Actions>

							</Actions>
						</Content>
					</LeftColumn>

					<RightColumn />
				</Banner3>
			</Slider>
		</Container>
	);
};
