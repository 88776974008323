import "tailwindcss/dist/base.css"
import "styles/globalStyles.css"
import React from "react"
import { css } from "styled-components/macro" //eslint-disable-line
import { ParallaxProvider } from 'react-scroll-parallax'

import ComponentRenderer from "ComponentRenderer.js"
import Inicio from "demos/Inicio"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

export default function App() {
	return (
		<ParallaxProvider>
			<Router>
				<Switch>
					<Route path="/components/:type/:subtype/:name">
						<ComponentRenderer />
					</Route>
					<Route path="/components/:type/:name">
						<ComponentRenderer />
					</Route>
					<Route path="/">
						<Inicio />
					</Route>
				</Switch>
			</Router>
		</ParallaxProvider>
	)
}
