import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "assets/images/icon.png"
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg"
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg"
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg"
import "./style.css"


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-8`
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`

const SocialLinksContainer = tw.div`mt-10`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left
`

const LinkList = tw.ul`mt-4 text-sm font-medium text-center`
const LinkListItem = tw.li`mt-3`
export default () => {
	return (
		<Container>
			<a className="animated bounceInRight wrap-icon-sugerencias" href="/components/innerPages/RegistroDatosPage" target="_blank">
				<img src="https://i.imgur.com/R6QRTbz.png" alt="..." title="Registro Nacional de Protección de Datos" />
				<div className="wrap-sugerencias-label">Registro Nacional de Protección de Datos</div>
			</a>
			<Content>
				<Row>
					<LinksContainer>
						<Link href="/">Inicio</Link>
						<Link href="/components/innerPages/AboutUsPage">Nosotros</Link>
						<Link href="/components/landingPages/ServiciosLandingPage">Servicios</Link>
						<Link href="/components/innerPages/BlogIndexPage">Noticias</Link>
						<Link href="/components/innerPages/CotizacionPage">Cotización</Link>
						<Link href="/components/innerPages/SugerenciasPage">Sugerencias</Link>
					</LinksContainer>
					<LogoContainer>
						<LogoImg src={logo} />
						<LogoText>LIP Aduanas S.A.</LogoText>
					</LogoContainer>
					<CompanyAddress>Av. Elmer Faucett esquina con Av. Tomas Valle</CompanyAddress>
					<CompanyAddress>S/N – Oficina 204-E - Callao</CompanyAddress>
					<LinkList>
						<LinkListItem>
							(+511) 614-0500
						</LinkListItem>
						<LinkListItem>
							atencionalcliente@lipaduanas.com
						</LinkListItem>
					</LinkList>
					<SocialLinksContainer>
						<SocialLink href="https://facebook.com">
							<FacebookIcon />
						</SocialLink>
						<SocialLink href="https://twitter.com">
							<TwitterIcon />
						</SocialLink>
						<SocialLink href="https://youtube.com">
							<YoutubeIcon />
						</SocialLink>
					</SocialLinksContainer>
					<CopyrightText>
						Copyright 2024, GNT servicios generales S.A. All Rights Reserved.
					</CopyrightText>
				</Row>
			</Content>
		</Container>
	)
}
