import React from "react"
import tw from "twin.macro" //eslint-disable-line
import { css } from "styled-components/macro" //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js"

import Hero from "components/hero/FullWidthWithImage"
import Features from "components/features/DashedBorderSixFeatures"
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js"
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js"
import ComunicadosSlider from "components/cards/ComunicadosSlider.js"
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js"
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration"
import RegistroLink from "components/forms/RegistroLink"
import Footer from "components/footers/MiniCenteredFooter"
import fotocomunicado from 'assets/images/comuu1.jpg'
import fotocomunicado2 from 'assets/images/comuu2.jpg'
import fotoperfil from 'assets/images/new.png'
import fotoHorti from 'assets/images/horti.png'
import fotoBeta from 'assets/images/beta.jpg'
import NoticiasSlider from "components/cards/NoticiasSlider"
import { Parallax } from 'react-scroll-parallax';

export default () => (
	<AnimationRevealPage disabled>
		<Parallax y={[-10, 10]}>
			<Hero />
		</Parallax>
		{/* <RegistroLink /> */}
		<MainFeature />
		<Features />
		{/* <Portfolio /> */}
		<ComunicadosSlider />
		<Testimonial
			subheading=""
			heading={
				<>
					La opinión de nuestros clientes <span tw="text-blue-500">nos importa</span>
				</>
			}
			description=""
			testimonials={[
				{
					imageSrc: fotocomunicado,
					profileImageSrc: fotoperfil,
					quote: "Opinión de New Transport.",
					customerName: "New Transport S.A.",
					customerTitle: ""
				},
				{
					imageSrc: fotocomunicado2,
					profileImageSrc: fotoBeta,
					quote: "Opinión",
					customerName: "Complejo Agroindustrial Beta S.A.",
					customerTitle: ""
				},
				{
					imageSrc: fotocomunicado2,
					profileImageSrc: fotoHorti,
					quote: "Opinión",
					customerName: "Hortifrut",
					customerTitle: ""
				}
			]}
			textOnLeft={true}
		/>
		<NoticiasSlider />
		<ContactUsForm />
		<Footer />
	</AnimationRevealPage>
)
